import { ClerkApp } from '@clerk/remix'
import { rootAuthLoader } from '@clerk/remix/ssr.server'
import { Heading, Toaster, Text } from '@optechai/design-system'
import { redirect, type LoaderFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from '@remix-run/react'
import '@optechai/design-system/styles/global.css'
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'

import { useUserMonitoring } from './services/user-monitoring.service'

export const loader: LoaderFunction = (args) => {
  const url = new URL(args.request.url)
  if (
    process.env.NODE_ENV === 'production' &&
    url.hostname !== 'app.lorikeetcx.ai'
  ) {
    url.hostname = 'app.lorikeetcx.ai'
    return redirect(url.toString(), { status: 301 })
  }

  return rootAuthLoader(args)
}

function App() {
  useUserMonitoring()
  return (
    <html className="rebrand h-full" lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="website" name="og:type" />
        <meta content="Lorikeet" name="og:title" />
        <meta
          content="AI-backed solution for managing, creating and editing workflows."
          name="og:description"
        />
        <meta
          content="https://app.lorikeetcx.ai/og-image.png"
          name="og:image"
        />
        <meta content="https://app.lorikeetcx.ai" name="og:url" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <Toaster />
      </body>
    </html>
  )
}

export default withSentry(ClerkApp(App))

/* 404 page */
const CustomBoundary = () => {
  const error = useRouteError()

  captureRemixErrorBoundaryError(error)

  if (isRouteErrorResponse(error)) {
    return (
      <html className="rebrand">
        <head>
          <title>Page Not Found</title>
          <Meta />
          <Links />
        </head>
        <body>
          <div className="flex min-h-screen flex-col items-center justify-center py-2">
            <Heading variant="h1">{error.status}</Heading>
            <Heading variant="h2">{error.statusText}</Heading>
            <Text>
              This page does not exist. Try going back <a href="/">home</a>.
            </Text>
          </div>
          <Scripts />
        </body>
      </html>
    )
  }

  return (
    <html className="rebrand">
      <head>
        <title>Server Error</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex min-h-screen flex-col items-center justify-center py-2">
          <Heading variant="h1">Server Error</Heading>
          <Text>
            The Lorikeet team have been notified. Please refresh and try again.
            If you're stuck, reach out or email{' '}
            <a href="mailto:support@lorikeetcx.ai">support@lorikeetcx.ai</a>
          </Text>
        </div>
        <Scripts />
      </body>
    </html>
  )
}

export const ErrorBoundary = CustomBoundary
